
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { VuetifyMixin, MyCoachMixin, SportsSelectorMixin, DebounceMixin, ShowcaseEventMixin, StringsMixin, BAIconsMixin } from "@/mixins";
import { DominantSideSelectorMixin } from "@/mixins/selectors/DominantSideSelector";
import { ScoutingReportScoreSelectorMixin } from "@/mixins/selectors/ScoutingReportScoreSelector";
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import ProfilePictureProvider from '@/components/hoc/ProfilePictureProvider.vue';
import ScoreSelector from '@/components/forms/ScoreSelector.vue';
import { ScoutingReportPlayerDetails, ScoutingReportGameDetails, ScoutingReportEvaluationMatrix, ContactInfo, ScoutingReportEvaluationMatrixNotes, AthleteAutocompleteInfo, AthleteScoutingReportDetails } from '@best-athletes/ba-types';
import ScoutingReportFormPendingSave from './ScoutingReportFormPendingSave.vue';
import ScoutingReportsInfoProvider, { LoadScoutingDataParams } from '../hoc/ScoutingReportsInfoProvider.vue';
import ScoutingReportNotesBtn from "@/components/scoutingReport/ScoutingReportNotesBtn.vue";
import { PageState } from '@/models/PageState';
import { athleteApi } from '@/api/AthleteApi';
import { teamApi } from '@/api/TeamApi';
import { PlayerTypeValues } from '@/../types/enums'
import { EventLocationModel, TeamModel } from "@/models";
import { ObjectCache } from "@/helpers/object-cache";

interface ScoutingReportFormValue{
	id?: string;
	contactInfo: ContactInfo;
	athleteId: string | null;
	lastModified: Date | null;
	anonymousCoach: boolean | null;
	playerDetails: ScoutingReportPlayerDetails;
	copyFromReport: ScoutingReportModel | null;
	gameDetails: ScoutingReportGameDetails;
	evaluationMatrix: ScoutingReportEvaluationMatrix;
	evaluationMatrixNotes: ScoutingReportEvaluationMatrixNotes;
}

@Component({
	components: {
		ScoreSelector,
		Page,
		ProfilePictureProvider,
		ScoutingReportsInfoProvider,
		ScoutingReportFormPendingSave,
		ScoutingReportNotesBtn
	},
})
export default class ScoutingReportForm extends Mixins(
	DominantSideSelectorMixin,
	MyCoachMixin,
	SportsSelectorMixin,
	ScoutingReportScoreSelectorMixin,
	VuetifyMixin,
	DebounceMixin,
	ShowcaseEventMixin,
	StringsMixin,
	BAIconsMixin
) {
	PlayerTypeValues = PlayerTypeValues;

	@Prop({ default: null, type: String }) newReportAthleteId: string | null;
	@Prop({ }) teamId: string;
	@Prop({ default: false, type: Boolean }) disabled: boolean;
	@Prop({ default: false, type: Boolean }) loading: boolean;
	@Prop({ default: null, type: String }) report: ScoutingReportModel | null;

	get IsAthleteLoaded(): boolean {
		if( !this.newReportAthleteId ) return true;
		return this.athleteLoaded;
	}
	athleteLoaded = false;
	async loadAthlete(): Promise<void> {
		this.athleteLoaded = false;
		const athleteBaseProfile = await athleteApi.getBasicProfile(this.newReportAthleteId);
		if( athleteBaseProfile != null ){

			// find player using email
			var res = await athleteApi.athleteAutocomplete(athleteBaseProfile.email, {});

			// expect exactly one athlete. if the search fails, try using athlete last name or first name or full name
			if( res.count != 1 ){
				res = await athleteApi.athleteAutocomplete(athleteBaseProfile.lastName, {});
				if( res.count != 1 ){
					res = await athleteApi.athleteAutocomplete(athleteBaseProfile.firstName, {});
					if( res.count != 1 ){
						res = await athleteApi.athleteAutocomplete(this.FullName(athleteBaseProfile.firstName, athleteBaseProfile.lastName), {});
					}
				}
			}

			// if exactly one athlete is found, obtain the details and fill out the form
			if( res.count == 1 ) {
				const athleteProfile = res.docs[0];
				const athleteLinkDetails = await athleteApi.getScoutingReportDetails(athleteProfile.id);
				this.athleteLinkToForm(athleteProfile, athleteLinkDetails);				
			}
		}
		this.athleteLoaded = true;
	}

	locationsCache: ObjectCache<EventLocationModel> = new ObjectCache<EventLocationModel>();
	async loadEvent() {
		await this.loadShowcaseEvents();

		// automatically fill out the location with the current showcase event location
		if( this.HasShowcaseEvents ) {
			const event = this.showcaseEvents[this.showcaseEvents.length - 1];
			const locationDetails = await event.getLocation(this.locationsCache);
			if( event.IsToday ) this.formValue.gameDetails.location = locationDetails.name;
		}
	}
	created(): void{
		this.loadEvent();		
		
		// if a new report is requested for a specific athlete, load the athlete information
		if( this.IsNotEmpty(this.newReportAthleteId) ) this.loadAthlete();

		if( this.IsNotEmpty(this.report) ){
			// report has been included,
			// load the report
			if (this.report.contactInfo === undefined || this.report.contactInfo === null) {
				this.report.contactInfo = {
					firstName: '',
					lastName: '',
					email: ''
				}
			} 
			this.loadReportToForm(this.report);
		}
	}
	@Watch('report') reportUpdated(): void{
		if(this.report !== null){
			this.loadReportToForm(this.report);
		}
	}

	get CardStyle(): Record<string, any>{
		return {
			border: `1px solid ${this.getColor('baColorGray8')}`,
		};
	}

	get EvaluationLabelClasses(): Record<string, boolean>{
		return {
			'my-auto': !this.$vuetify.breakpoint.smAndDown,
			'my-2': this.$vuetify.breakpoint.smAndDown,
		};
	}
	get EvaluationCols(): {left: Record<string, any>, right: Record<string, any>}{
		return {
			left: {
				cols:"12", md:"3", lg:"4",
			},
			right: {
				cols:"12", md:"9", lg:"8",
			},
		}
	}

	private loadReportToForm(record: ScoutingReportModel){
		const formValue = Object.assign(this.emptyForm(), record);
		// Cast old scouting report scores to numbers
		for(const key of Object.keys(formValue.evaluationMatrix)){
			formValue.evaluationMatrix[key] = +formValue.evaluationMatrix[key];
		}
		this.formValue = formValue;
		this.isYouth = this.IsYouth;
	}

	exportFormToReport(formValue: ScoutingReportFormValue): ScoutingReportModel{
		this.formValue.contactInfo.firstName = this.formValue.playerDetails.firstName;
		this.formValue.contactInfo.lastName = this.formValue.playerDetails.lastName;
		this.formValue.gameDetails.coachId = this.MyCoachId
		return new ScoutingReportModel().load({
			...formValue,
			athleteId: formValue.athleteId,
			playerDetails:{
				...formValue.playerDetails,
			},
			contactInfo:{
				...formValue.contactInfo,
			},
			gameDetails:{
				...formValue.gameDetails,
			},
			evaluationMatrix:{
				...formValue.evaluationMatrix,
				performanceScore: this.ComputedPerformanceScore,
			},
			evaluationMatrixNotes:{
				...formValue.evaluationMatrixNotes,
			},
			copyFromReport: undefined,
		});
	}

	get CopyFromScoutingReportsQuery(): LoadScoutingDataParams{
		return {
			query:{
				$match:{
					submitted: false,
				}
			},
			options: {
				limitPerPage: 10,
				sort:{
					fields: [{
						field: 'gameDetails.evaluationDate',
						desc: true,
					}],
				}
			}
		};
	}


	copyDetailsFromReport(report: ScoutingReportModel | null): void{
		if(report === null){
			return this.resetGameDetails();
		}
		this.formValue.copyFromReport = report;
		this.formValue.gameDetails = {
			...report.gameDetails,
		};
	}
	resetGameDetails(): void{
		this.formValue.copyFromReport = null;
		this.formValue.gameDetails = {
			...this.emptyForm().gameDetails,
		};
	}

	get ScoreSelectorValidationProps(){
		return {
			rules: 'required',
		};
	}

	athleteLink: AthleteAutocompleteInfo | null = null;
	athleteLinkState: PageState = new PageState("Ready");
	onResetAthleteLink(): void{
		this.athleteLink = null;
		this.teamId = undefined;
		this.team = undefined;
		this.newReportAthleteId = null;
		this.resetAthleteLinkForm();
	}
	resetAthleteLinkForm(): void{
		const form = this.emptyForm();
		this.formValue.athleteId = form.athleteId;
		this.formValue.playerDetails = {
			...form.playerDetails,
		};
		this.formValue.contactInfo = {
			...form.contactInfo,
		};		
	}	

	get IsLinked(): boolean {
		return this.athleteLink !== null || this.newReportAthleteId !== null;
	}

	@Watch('athleteLink')
	async loadLinkAthleteDetails(athleteLink: AthleteAutocompleteInfo | null, oldAthleteLink: AthleteAutocompleteInfo | null): Promise<void>{
		if(athleteLink === null){
			return this.resetAthleteLinkForm();
		}
		if(athleteLink?.id !== oldAthleteLink?.id){
			this.resetAthleteLinkForm();
		}
		this.athleteLinkState = new PageState("Loading");
		await new Promise(r => setTimeout(r, 1000));
		try{
			const athleteLinkDetails = await athleteApi.getScoutingReportDetails(athleteLink.id);
			await this.athleteLinkToForm(athleteLink, athleteLinkDetails);
		}catch(e){
			this.athleteLinkState = PageState.getPageState(e);
		}
		this.athleteLinkState = new PageState("Ready");
	}

	team: TeamModel;
	get AthleteName(): string {
		return this.formValue.playerDetails.firstName + " " + this.formValue.playerDetails.lastName;
	}
	async athleteLinkToForm(athleteLink: AthleteAutocompleteInfo, athleteLinkDetails: AthleteScoutingReportDetails) {
		if( this.IsEmpty(this.teamId) ) this.teamId = athleteLink.currentTeam;
		if( this.IsEmpty(this.teamId) ) this.teamId = athleteLinkDetails.currentTeam;
		this.team = await teamApi.findById(this.teamId);
				
		this.resetAthleteLinkForm();
		this.formValue.playerDetails.firstName = athleteLinkDetails.firstName;
		this.formValue.playerDetails.lastName = athleteLinkDetails.lastName;
		this.formValue.playerDetails.dateOfBirth = athleteLinkDetails.dateOfBirth;
		this.formValue.playerDetails.yearOfBirth = athleteLinkDetails.yearOfBirth;
		this.formValue.playerDetails.dominantFoot = athleteLinkDetails.dominantFoot;
		this.formValue.playerDetails.jerseyNumber = this.IsNotEmptyArray(athleteLinkDetails.jerseyNumbers)? +athleteLinkDetails.jerseyNumbers[0] : 12;
		this.formValue.playerDetails.position = athleteLinkDetails.positions[0];

		// Contact Info
		this.formValue.contactInfo.firstName = athleteLinkDetails.firstName;
		this.formValue.contactInfo.lastName = athleteLinkDetails.lastName;
		this.formValue.contactInfo.email = athleteLinkDetails.email;
		// Auto-link athlete
		this.formValue.athleteId = athleteLink.id;
		// team information
		if( this.IsNotEmpty(this.team) ) {
			this.formValue.gameDetails.teamName = this.team.name;
			const teamPlayer = this.team.players.find(p => p.athleteId === athleteLink.id );
			if( this.IsNotEmpty(teamPlayer) ) {
				this.formValue.playerDetails.jerseyNumber = Number(teamPlayer.number);
			}
		}
	}

	formValue: ScoutingReportFormValue = this.emptyForm();
	pendingSave: boolean = false;
	get LastModified(): Date | null{
		return this.formValue.lastModified;
	}

	/**
	 * Computed overall score. Only available once all scores are submitted
	 */
	get ComputedPerformanceScore(): number | null{
		const { evaluationMatrix } = this.formValue;
		const skipKeys: Array<keyof ScoutingReportEvaluationMatrix> = ["performanceScore", "potentialScore"];
		const enteredScores = Object.entries(evaluationMatrix)
			.filter(([key]) => !skipKeys.includes(key as keyof ScoutingReportEvaluationMatrix)) // Ignore Overall Scores
			.map(([,val]) => val); // Return entered values only
		if(enteredScores.includes(null) || enteredScores.includes(0)){ // One or more scores are not entered yet
			return null;
		}
		const avgScore = enteredScores.reduce((a,b) => a+b) / enteredScores.length;
		const avgScore2Decimals = (~~(avgScore * 100)/100);
		return avgScore2Decimals;
	}

	private emptyForm(): ScoutingReportFormValue{
		return {
			athleteId: null,
			contactInfo: {
				email: '',
				firstName: '',
				lastName: '',
			},
			lastModified: null,
			anonymousCoach: null,
			playerDetails: {
				firstName: "",
				lastName: "",
				position: "",
				playerType: "",
				dateOfBirth: null,
				yearOfBirth: null,
				dominantFoot: null,
				performanceScore: null,
				potentialScore: null,
				jerseyNumber: null,
				competitiveLevel: null,
			},
			copyFromReport: null,
			gameDetails: {
				coachId: "",
				evaluationDate: new Date(),
				location: "",
				weatherConditions: "",
				teamName: "",
				opponent: "",
			},
			evaluationMatrix: {
				technical: null,
				tactical: null,
				physical: null,
				mental: null,

				attackingOrganization: null,
				attackingTransition: null,
				attackingSetPlays: null,
				defendingOrganization: null,
				defendingTransition: null,
				defendingSetPlays: null,

				competitive: null,
				resilience: null,
				intelligence: null,
				speed: null,
				presence: null,

				performanceScore: null,
				potentialScore: null,
			},
			evaluationMatrixNotes: {
				technical: null,
				tactical: null,
				physical: null,
				mental: null,

				attackingOrganization: null,
				attackingTransition: null,
				attackingSetPlays: null,
				defendingOrganization: null,
				defendingTransition: null,
				defendingSetPlays: null,

				competitive: null,
				resilience: null,
				intelligence: null,
				speed: null,
				presence: null,

				performanceScore: null,
				potentialScore: null,
			}
		};
	}

	errorMessage: string | null = null;
	cancel(): void{
		this.$emit('cancel');
	}

	async save(): Promise<void> {
		this.$emit('save', this.exportFormToReport(this.formValue));
		this.pendingSave = false;
	}
	debounceSave(): void{
		// Only auto-save when editing
		if(this.formValue.id === undefined) return;
		this.pendingSave = true;
		this.debounceCallback('save', () => this.save());
	}
	async saveAndClose(): Promise<void> {
		this.$emit('submit', this.exportFormToReport(this.formValue));
	}

	submitDialogVisible: boolean = false;
	async initalSubmit(validate: () => Promise<boolean>): Promise<void> {
		const isValid = await validate();
		if (!isValid) {
			this.errorMessage = 'Please ensure all required fields and scores for the evaluation matrix are provided.';
			this.$vuetify.goTo(0);
		}
		else {
			this.submitDialogVisible = true;
		}
	}
	async submit(validate: () => Promise<boolean>): Promise<void>{
		this.errorMessage = null;
		const isValid = await validate();
		if (isValid){
			const report = this.exportFormToReport(this.formValue);
			report.submitted = true;
			this.$emit('submit', report);
		}
	}
	async cancelSubmit(): Promise<void> {
		this.submitDialogVisible = false;
		this.formValue.contactInfo.email = '';
	}

	notesDialogVisible: boolean = false;
	notesFor: (keyof ScoutingReportEvaluationMatrix) | null = null;
	openNotesDialog(notesFor: (keyof ScoutingReportEvaluationMatrix)) {
		this.notesDialogVisible = true;
		this.notesFor = notesFor;
	}

	get matrixNotesModel(): string | null {
		return this.notesFor === null ? null : this.formValue.evaluationMatrixNotes[this.notesFor];
	}
	set matrixNotesModel(value: string) {
		this.formValue.evaluationMatrixNotes[this.notesFor] = value
	}

	isYouth: boolean = true;

	YouthLevels = [
		'1 - International',
		'2 - Pro academy',
		'3 - High performance',
		'4 - Competitive',
		'5 - Pre competitive',
	];
	SeniorLevels = [
		'1 - International Top Leagues',
		'2 - Secondary pro leagues',
		'3A - University',
		'3B - League 1',
		'3C - USL League 2',
		'4 - Senior competitive',
		'5 - Senior non competitive',
	]

	get IsYouth(): boolean {
		if( !this.formValue.playerDetails.competitiveLevel ) return true;
		if( this.SeniorLevels.includes(this.formValue.playerDetails.competitiveLevel) ) return false;
		if( this.YouthLevels.includes(this.formValue.playerDetails.competitiveLevel) ) return true;

		return true;
	}

	competitiveYouthChange(): void{
		this.formValue.playerDetails.competitiveLevel = null;
		// this.debounceSave();
	}
	
	get CompetitiveLevelItems(): string[] {
		if( this.isYouth ) return this.YouthLevels;
		return this.SeniorLevels;
	}

	onlyUnique(reports : ScoutingReportModel[]): ScoutingReportModel[] {
		let uniqueReports = [];
		let prev = null;
		reports.forEach((report) => {
			if( !prev ) {
				uniqueReports.push(report);
			} else {
				if( report.gameDetails.evaluationDate.getDate() !== prev.gameDetails.evaluationDate.getDate() ||
					report.gameDetails.location !== prev.gameDetails.location ||
					report.gameDetails.teamName !== prev.gameDetails.teamName ||
					report.gameDetails.opponent !== prev.gameDetails.opponent ) {
						uniqueReports.push(report);
					}
			}
			prev = report;
		})
		return uniqueReports;
	}
}
