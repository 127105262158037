
import { Component, Mixins } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { VuetifyMixin, MyCoachMixin, CoachRoutingMixin, BAIconsMixin, PaginatedTableMixin, StringsMixin } from "@/mixins";
import { DataTableHeader } from 'vuetify';
import { RepositoryQuery, QueryOptions, PaginatedResponse } from '@/../types/interfaces';
import { ScoutingReportModel, ScoutingReportModelEx } from '@/models/scoutingReport/ScoutingReportModel';
import { AthleteProfileModel, CoachProfileModel } from '@/models';
import { scoutingReportApi } from "@/api/ScoutingReportApi";
import ScoutingReportBrief from '@/components/profile/athlete/ScoutingReportBrief.vue';

@Component({
	components: {
		Page,
		ScoutingReportBrief,
	},
})
export default class CoachScoutingSummary extends Mixins(PaginatedTableMixin, VuetifyMixin,	BAIconsMixin, StringsMixin, MyCoachMixin, CoachRoutingMixin) {
	scoutingReportsDetails: Array<ScoutingReportModelEx> = [];

	mounted() {
		this.tableOptions.sortBy = ['submitted','createdAt'];
		this.tableOptions.sortDesc = [true, true];
		this.localForagePersistFields = [['search', ''],['tableOptions.page', 1],['tableOptions.itemsPerPage', 25]];
	}

	athletes: Array<AthleteProfileModel> = [];
	coaches: Array<CoachProfileModel> = [];
	async loadDetails(scoutingReports: Array<ScoutingReportModel>): Promise<Array<ScoutingReportModelEx>> {
		return Promise.all( scoutingReports.map(report => ScoutingReportModelEx.fromScoutingReport(report, this.athletes, this.coaches)) );
	}
	async loadTable() {
		this.isLoading = true;
		this.isLoaded = false;
		const query: RepositoryQuery<ScoutingReportModel> = this.TableQuery<ScoutingReportModel>(this.search, ['athleteId', 'notes', 'playerDetails.firstName', 'playerDetails.lastName', 'playerDetails.email', 'scoutingReport.gameDetails.teamName']);
		query.$match = {...query.$match, ...{ 'gameDetails.coachId': this.MyCoachId }};
		const options: QueryOptions = this.TableQueryOptions;
		const response: PaginatedResponse<ScoutingReportModel> = await scoutingReportApi.queryAll(query, options);
		this.scoutingReportsDetails = await this.loadDetails(response.docs);
		this.dataItemsCount = response.total;
		this.isLoaded = true;
		this.isLoading = false;
	}

	get TableLoading(): boolean {
		return this.isLoading;
	}
	get PageLoading(): boolean {
		return this.TableLoading || this.isLoading;
	}

	get TableHeaders(): Array<DataTableHeader<any>> {
		let headers: Array<DataTableHeader<any>> = [
			{ text: 'Date', value: 'createdAt', sortable: false },
			{ text: 'Player', value: 'name', sortable: false },
			{ text: 'Team', value: 'team', sortable: false },
			{ text: 'Number', value: 'number', sortable: false, width:'20', align:'center' },
			{ text: 'Location', value: 'location', sortable: false },
			{ text: 'Status', value: 'submitted', sortable: false },
			{ text: 'Score', value: 'performance', sortable: false, width:'90', align:'center' },
			{ text: 'Potential', value: 'potential', sortable: false, width:'90', align:'center' },
		];
		if( this.IsLargeScreen ) {
			headers.push({text: '', value: 'actions', sortable: false });
			headers.push({text: '', value: 'data-table-expand', sortable: false});
		}
		return headers;
	}

}
