
import { Component, Mixins } from 'vue-property-decorator';
import { AppHostnameMixin, BAIconsMixin, MyCoachMixin, MyTeamsMixin, VuetifyMixin } from '@/mixins';
import TeamMiniCard from '../ui/TeamMiniCard.vue';
import { TeamModel } from '@/models/team';

@Component({
	components: { 
		TeamMiniCard
	},
})
export default class TeamList extends Mixins(MyTeamsMixin, VuetifyMixin, BAIconsMixin, AppHostnameMixin, MyCoachMixin){

	expanded: boolean = false;
	numToShow = 3;

	get CoachTeams(): Array<TeamModel> {
		return this.MyTeamsSorted(this.MyCoachProfile?.teams);
	}
	get TeamsToShow(): TeamModel[] {
		if( this.CoachAppActive ) return this.ShowAll? this.CoachTeams : this.CoachTeams.slice(0, this.numToShow);
		return this.ShowAll? this.MyActiveTeams : this.MyActiveTeams.slice(0, this.numToShow);
	}

	get ShowAll(): boolean {
		return this.NumTeams <= this.numToShow || this.expanded;
	}

	get ShowMore(): boolean {
		return this.MyActiveTeams.length > this.numToShow && !this.expanded;
	}

	get ShowLess(): boolean {
		return this.MyActiveTeams.length > this.numToShow && this.expanded;
	}

	openTeamDashboard(teamId){
		this.$router.push({ name: 'TeamDashboard', params: { teamId }})
	}
}
