
import Page from '@/views/Page.vue';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, StringsMixin, TeamDashboardMixin, PaginatedTableMixin, OrgResolverMixin, TeamAthletesMixin, CoachRoutingMixin, MindsetDisplayMixin, LocalForageMixin } from '@/mixins';
import { DataTableHeader } from 'vuetify';
import TeamDetailsCard from '../components/teams/TeamDetailsCard.vue';
import { AthleteProgressAbbreviations, AthleteProgressFields } from '@/models/team'

@Component({
	components: {
		Page,
		TeamDetailsCard,
	}
})
export default class CoachTeamProgressDashboard extends Mixins(VuetifyMixin, StringsMixin, LocalForageMixin, CoachRoutingMixin, TeamDashboardMixin, PaginatedTableMixin, OrgResolverMixin, TeamAthletesMixin, MindsetDisplayMixin) {
	@Prop({ required: true }) teamId: string;

	allColumns = [];
	selectedColumns: Array<string> = [];	
	localForagePersistFields: Array<string | [string, any]> = [['selectedColumns', ['Email']]];

	mounted() {
		this.InitializeTableColumns()
		this.tableOptions.sortBy = ['name'];
		this.tableOptions.sortDesc = [false];
		this.tableOptions.itemsPerPage = 25;
	}

	async InitializeTableColumns() {
		const colFilter = ['Account'];
		const onboardingColumns = ['Email','Date of Birth','Location'];
		this.allColumns = Object.values(AthleteProgressFields).filter(v => !colFilter.includes(v));
		if( this.IsEmptyArray(this.selectedColumns) ) {
			this.clearPersistedFields();
			await this.UpdateSelectedColumns([...this.allColumns].filter(col => !onboardingColumns.includes(col)));
		}
	}
	async UpdateSelectedColumns(columns: Array<string>) {
		this.selectedColumns = columns;
		this.onUpdateColumns();
	}

	get TableHeaders(): Array<DataTableHeader<any>> {
		let headers: Array<DataTableHeader<any>> = [
			{ text: 'Name', value: 'name', sortable: false }
		];
		const alignment: 'start' | 'center' | 'end' = 'center';
		const showColumns = Object.keys(AthleteProgressFields).filter(key => this.selectedColumns.includes(AthleteProgressFields[key]));
		const columns = Object.keys(AthleteProgressFields).filter(key => showColumns.includes(key) ).map(key => {
			return { text: AthleteProgressAbbreviations[key], value: key, sortable: false, align : alignment }
		});
		headers.push(...columns);

		return headers;
	}

	async loadTable() {
		if( this.IsEmpty(this.team) ) this.loadTeam();
		if( this.IsEmptyArray(this.teamAthletes)) this.loadTeamAthletes();
	}
	
	get PageLoading(): boolean {
		return this.TableLoading || this.IsTeamLoading;
	}
	get TableLoading(): boolean {
		return this.IsTeamLoading || this.loadingAthletes;
	}
	get TotalItems(): number {
		return this.TeamPlayersCount;
	}

	ProgressColor(attribute: string): string {
		if( !attribute ) return 'error';
		if( this.IsEmpty(attribute) ) return 'error' ;
		return 'success';
	}
	ProgressIcon(attribute: string): string {
		if( !attribute ) return 'mdi-checkbox-blank-outline';
		if( this.IsEmpty(attribute) ) return 'mdi-checkbox-blank-outline' ;
		return 'mdi-check-circle';
	}

	async onUpdateColumns() {
		this.persistField(this.LocalForagePersistFieldKeys);
	}
	async onRemoveColumn(col: string) {
		const index = this.selectedColumns.findIndex(c => c === col);
		if( index < 0 ) return;
		this.selectedColumns.splice(index, 1);
		this.onUpdateColumns();
	}

	async onSelectAllColumns() {
		this.UpdateSelectedColumns([...this.allColumns])
	}
	async onRemoveAllColumns() {
		this.selectedColumns = [];
	}
}

